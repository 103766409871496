'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { useDefaultPath } from '~/hooks/useDefaultPath';

const HomePage = () => {
  const APP_DEFAULT_PATH = useDefaultPath();
  const router = useRouter();

  useEffect(() => {
    router.replace(APP_DEFAULT_PATH);
  }, [APP_DEFAULT_PATH, router]);

  return <AuthGuard>{null}</AuthGuard>;
};

export default HomePage;
